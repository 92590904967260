import React from 'react';
import { Col, Row, Flex } from 'antd';
import { Animate, AnimateGroup } from 'react-simple-animate';

import { colorSelected, colorSecondary } from '../const/colors';

import RevealedWordsList from './RevealedWordsList';

interface WordDisplayProps {
    word: string;
    wordLength: number;
    isDiscovered: boolean;
    isMasterWord?: boolean;
    isTabletOrMobile?: boolean;
}

// TODO: add small animations to interactions
// TODO: create const file that match consts in the backend

const WordDisplay = (props: WordDisplayProps) => {
    const charArray = props.isDiscovered
        ? props.word.toUpperCase().split('')
        : Array.from('_'.repeat(props.wordLength));
    return (
        <Row
            key={props.word}
            style={{
                margin: '5px 0',
                ...(props.isMasterWord
                    ? { border: `2px solid ${colorSelected}`, borderRadius: '10px', padding: 2, width: 'max-content' }
                    : {}),
            }}
        >
            <AnimateGroup play={props.isDiscovered}>
                {charArray.map((char, index) => (
                    <div
                        key={index}
                        className='signika-negative-word'
                        style={{
                            margin: 3,
                            background: 'rgba(255, 255, 255, 0.1)',
                            fontSize: props.isTabletOrMobile ? '30px' : '35px',
                            color: char == '_' ? 'rgba(255, 255, 255, 0.0)' : colorSelected,
                            borderRadius: 5,
                            border: props.isMasterWord ? colorSecondary : '2px solid rgba(255, 255, 255, 0.32)',
                            width: props.isTabletOrMobile ? '40px' : '50px',
                            textAlign: 'center',
                        }}
                    >
                        {
                            <Animate
                                key={index}
                                sequenceIndex={index}
                                sequenceId={index}
                                start={{ opacity: 0, transform: 'translateY(-10px)' }}
                                end={{ opacity: 1, transform: 'translateY(0)' }}
                                duration={0.1}
                            >
                                {char}
                            </Animate>
                        }
                    </div>
                ))}
            </AnimateGroup>
        </Row>
    );
};

const WordsDisplay = (props: { isTabletOrMobile: boolean; gameState: any }) => {
    const revealedWords = props?.gameState?.game?.revealedWords || [];
    const wordsElements = [
        <Row key={0}>
            <WordDisplay
                key={0}
                word={revealedWords[0]?.word}
                isDiscovered={revealedWords[0]?.type === 'REVEALED'}
                wordLength={revealedWords[0]?.length || 3}
                isTabletOrMobile={props.isTabletOrMobile}
            />
        </Row>,
        <Row key={1}>
            <WordDisplay
                key={1}
                word={revealedWords[1]?.word}
                isDiscovered={revealedWords[1]?.type === 'REVEALED'}
                wordLength={revealedWords[1]?.length || 3}
                isTabletOrMobile={props.isTabletOrMobile}
            />
        </Row>,
        <Row key={2}>
            <WordDisplay
                key={2}
                word={revealedWords[2]?.word}
                isDiscovered={revealedWords[2]?.type === 'REVEALED'}
                wordLength={revealedWords[2]?.length || 3}
                isTabletOrMobile={props.isTabletOrMobile}
            />
        </Row>,
        <Row key={3}>
            <WordDisplay
                key={3}
                word={revealedWords[3]?.word}
                isDiscovered={revealedWords[3]?.type === 'REVEALED'}
                wordLength={revealedWords[3]?.length || 3}
                isTabletOrMobile={props.isTabletOrMobile}
            />
        </Row>,
        <Row key={4}>
            <WordDisplay
                key={4}
                word={revealedWords[4]?.word}
                isDiscovered={revealedWords[4]?.type === 'REVEALED'}
                wordLength={revealedWords[4]?.length || 4}
                isTabletOrMobile={props.isTabletOrMobile}
            />
        </Row>,
        <Row key={5}>
            <WordDisplay
                key={5}
                word={revealedWords[5]?.word}
                isDiscovered={revealedWords[5]?.type === 'REVEALED'}
                wordLength={revealedWords[5]?.length || 4}
                isTabletOrMobile={props.isTabletOrMobile}
            />
        </Row>,
        <Row key={6}>
            <WordDisplay
                key={6}
                word={revealedWords[6]?.word}
                isDiscovered={revealedWords[6]?.type === 'REVEALED'}
                wordLength={revealedWords[6]?.length || 4}
                isTabletOrMobile={props.isTabletOrMobile}
            />
        </Row>,
        <Row key={7}>
            <WordDisplay
                key={7}
                word={revealedWords[7]?.word}
                isDiscovered={revealedWords[7]?.type === 'REVEALED'}
                wordLength={revealedWords[7]?.length || 5}
                isTabletOrMobile={props.isTabletOrMobile}
            />
        </Row>,
        <Row key={8}>
            <WordDisplay
                key={8}
                word={revealedWords[8]?.word}
                isDiscovered={revealedWords[8]?.type === 'REVEALED'}
                wordLength={revealedWords[8]?.length || 5}
                isTabletOrMobile={props.isTabletOrMobile}
            />
        </Row>,
        <Row key={9}>
            <WordDisplay
                key={9}
                word={revealedWords[9]?.word}
                isDiscovered={revealedWords[9]?.type === 'REVEALED'}
                wordLength={revealedWords[9]?.length || 6}
                isTabletOrMobile={props.isTabletOrMobile}
            />
        </Row>,
        <Row key={10}>
            <WordDisplay
                key={10}
                word={revealedWords[10]?.word}
                isDiscovered={revealedWords[10]?.type === 'REVEALED'}
                wordLength={revealedWords[10]?.length || 6}
                isTabletOrMobile={props.isTabletOrMobile}
            />
        </Row>,
        <WordDisplay
            key={11}
            isMasterWord={true}
            word={revealedWords[11]?.word}
            isDiscovered={revealedWords[11]?.type === 'REVEALED'}
            wordLength={revealedWords[11]?.length || 9}
            isTabletOrMobile={props.isTabletOrMobile}
        />,
    ];
    return props.isTabletOrMobile ? (
        <div style={{ width: '100%', padding: '0 0 0 30px' }}>
            {wordsElements.slice(0, 11).map((element: any) => (
                <Row>
                    <Flex style={{ width: '100%' }}>{element}</Flex>
                </Row>
            ))}
            <Flex style={{ width: '100%' }}>{wordsElements[11]}</Flex>
        </div>
    ) : (
        <Flex style={{ width: '100%' }} align='center' justify='center'>
            <Row>
                <Col span={6} key={0}>
                    {wordsElements.slice(0, 4)}
                </Col>
                <Col span={8} key={1}>
                    {wordsElements.slice(4, 8)}
                </Col>
                <Col span={10} key={2}>
                    {wordsElements.slice(8, 12)}
                </Col>
            </Row>
        </Flex>
    );
};

export default WordsDisplay;
