import { Flex, Button, Row, Col, Spin, Space, Input } from 'antd';
import { UploadOutlined, LoadingOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';

import { GetWordsClassify, SubmitClassifiedWords } from '../apis/backend';

const WordClassification = {
    APPROVED: 'APPROVED',
    REMOVED: 'REMOVED',
    UNCLASSIFIED: 'UNCLASSIFIED',
};

const limit = 100;

const Catalog = () => {
    const [words, setWords] = useState(new Map<string, string>([]));
    const [loading, setLoading] = useState(true);
    const [metrics, setMetrics] = useState<{ classifiedCount: number; totalCount: number }>({
        classifiedCount: 0,
        totalCount: 0,
    });
    const [key, setKey] = useState('');

    useEffect(() => {
        setLoading(true);
        GetWordsClassify(limit).then((data: any) => {
            setWords(new Map<string, string>(data.words.map(({ word }: any) => [word, WordClassification.REMOVED])));
            setMetrics({ classifiedCount: data.classifiedCount, totalCount: data.totalCount });
            setLoading(false);
        });
    }, []);

    return loading ? (
        <Flex justify='center' align='center' gap='small' style={{ minHeight: '60vh', width: '100%' }}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
        </Flex>
    ) : (
        <div style={{ padding: '0 10px', minHeight: '80vh' }}>
            <Flex justify='center' gap='small'>
                <h1>Ferramenta de classificação de palavras</h1>
            </Flex>
            <Flex justify='center' gap='small'>
                <div>
                    {metrics.classifiedCount || 0} classificadas de {metrics.totalCount || 0} (
                    {((100 * metrics.classifiedCount) / metrics.totalCount).toFixed(2)}%)
                </div>
            </Flex>
            <br />
            <br />
            <Flex justify='center' align='flex-start' wrap gap='small'>
                {Array.from(words.keys()).map((word, index) => (
                    <Button
                        key={index}
                        type='primary'
                        style={{
                            margin: '5px',
                            backgroundColor:
                                words.get(word) === WordClassification.APPROVED
                                    ? 'green'
                                    : words.get(word) === WordClassification.REMOVED
                                      ? 'red'
                                      : 'black',
                        }}
                        onClick={(p: any) => {
                            const word = p.target.innerText;
                            switch (words.get(word)) {
                                case WordClassification.APPROVED:
                                    words.set(word, WordClassification.REMOVED);
                                    break;
                                case WordClassification.REMOVED:
                                    words.set(word, WordClassification.APPROVED);
                                    break;
                            }
                            setWords(new Map(words));
                        }}
                    >
                        {word}
                    </Button>
                ))}
            </Flex>
            <br />
            <br />
            <Flex justify='center' gap='small'>
                <Space.Compact>
                    <Input
                        value={key}
                        placeholder='Cole a chave de api'
                        type='password'
                        onChange={(e) => {
                            setKey(e.target.value);
                        }}
                    />
                    <Button
                        type='primary'
                        icon={<UploadOutlined />}
                        size='large'
                        onClick={() => {
                            console.log('Submitting', Array.from(words.entries()).slice(0, 10));
                            setLoading(true);
                            SubmitClassifiedWords(
                                key,
                                Array.from(words.entries()).map(([word, classification]) => ({
                                    word,
                                    state: classification,
                                    classifiedByUser: key,
                                }))
                            )
                                .then(() => {
                                    GetWordsClassify(limit).then((data: any) => {
                                        setWords(
                                            new Map<string, string>(
                                                data.words.map(({ word }: any) => [word, WordClassification.REMOVED])
                                            )
                                        );
                                        setMetrics({
                                            classifiedCount: data.classifiedCount,
                                            totalCount: data.totalCount,
                                        });
                                        setLoading(false);
                                    });
                                })
                                .catch((e) => {
                                    console.error('Error submitting', e);
                                });
                        }}
                    >
                        Submeter classificação
                    </Button>
                </Space.Compact>
            </Flex>
        </div>
    );
};

export default Catalog;
