import React from 'react';
import logo from './logo.png';
import './App.css';

import { Route, Routes, Link, BrowserRouter } from 'react-router-dom';
import { Breadcrumb, Layout, Col, Row, Card, Menu, Flex, FloatButton, Drawer } from 'antd';
import { colorBackground, colorSecondary } from './const/colors';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { v4 as uuidv4 } from 'uuid';

import Game from './components/Game';
import Catalog from './components/Catalog';
import HowToPlay from './components/HowToPlay';
import GameSetBuilder from './components/GameSetBuilder';

const { Header, Content, Footer, Sider } = Layout;

function App() {
    const [isOpenHowToPlay, setIsOpenHowToPlay] = React.useState(false);

    const showDrawerHowToPlay = () => {
        setIsOpenHowToPlay(true);
    };

    const onCloseDrawerHowToPlay = () => {
        setIsOpenHowToPlay(false);
    };

    let userId: any = localStorage.getItem('userId');
    if (userId == null) {
        userId = uuidv4();
        localStorage.setItem('userId', userId);
    }

    return (
        <BrowserRouter>
            <Layout style={{ background: colorBackground, color: colorSecondary }}>
                <Row style={{ padding: '100px' }}>
                    <Flex align='center' justify='center' style={{ width: '100%' }}>
                        <img src={logo} alt='Logo' style={{ width: '150px' }} />
                    </Flex>
                </Row>
                <Row>
                    <Routes>
                        <Route key={'default'} path={'/'} element={<Game userId={userId} />}></Route>
                    </Routes>
                    <Routes>
                        <Route key={'catalog'} path={'/catalog'} element={<Catalog />}></Route>
                    </Routes>
                    <Routes>
                        <Route key={'game_set_builder'} path={'/game_set_builder'} element={<GameSetBuilder />}></Route>
                    </Routes>
                </Row>
                <FloatButton
                    icon={<QuestionCircleOutlined />}
                    type='default'
                    style={{ right: 24 }}
                    onClick={showDrawerHowToPlay}
                />
                <Footer style={{ textAlign: 'center', background: colorBackground, color: colorSecondary }}>
                    {new Date().getFullYear()} Created by Ali Husseinat
                    <br />
                    <br />
                    All rights reserved.
                </Footer>
            </Layout>
            <Drawer onClose={onCloseDrawerHowToPlay} open={isOpenHowToPlay}>
                <HowToPlay />
            </Drawer>
        </BrowserRouter>
    );
}

export default App;
