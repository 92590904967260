import React from 'react';
import { Alert } from 'antd';

const HowToPlay = () => {
    return (
        <div>
            <h1 className='signika-negative-word'>Como jogar</h1>
            <br />
            <h2 className='signika-negative-word'>Objetivo</h2>
            <p>Descubra o máximo de palavras possíveis com as letras fornecidas no menor tempo possível.</p>
            <p>Palavras maiores valem mais pontos.</p>
            <p>A palavra mestra usa todas as letras disponíveis.</p>
            <p>Todo dia à meia noite selecionamos novas palavras pra você jogar.</p>
            <br />
            <h2 className='signika-negative-word'>Regras</h2>
            <p>
                Você pode usar cada letra apenas uma vez. Se houver mais de uma ocorrência daquela letra, ela pode ser
                repetida para cada ocorrência.
            </p>
            <p>
                Exemplo: as letras s, s, s, u, u, a, a, a, r, n podem formar "sussuarana", mas não podem formar
                "sussurrar", visto que a segunda precisaria de mais duas letras "r".
            </p>
            <p>
                Verbos conjugados não estão no nosso dicionário, por isso não aparecerão no tabuleiro. (Porém, você será
                penalizado caso tente algum, isso é algo que estamos trabalhando para melhorar.)
            </p>
            <p>A única relação entre as palavras é que elas podem ser formadas a partir do mesmo conjunto de letras.</p>
            <p>Você pode embaralhar as letras quantas vezes quiser sem penalidade.</p>
            <br />
            <br />
            <Alert
                message='O jogo ainda está em fase de testes, por isso muitos aspectos podem mudar e algumas coisas podem não funcionar como esperado. Estamos abertos a sugestões.'
                type='info'
            />
        </div>
    );
};

export default HowToPlay;
