import { useState, useEffect } from 'react';

import { Statistic, Flex, ConfigProvider, Tooltip } from 'antd';
import { colorSecondary, colorSelected } from '../const/colors';

import { GetStatistics } from '../apis/backend';

const formatSeconds = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
};

const StatisticsDisplay = (props: { gameState: any; userId: string }) => {
    const [statistics, setStatistics] = useState<any>({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        GetStatistics(props.userId).then((data: any) => {
            setStatistics(data);
            setLoading(false);
            console.log(data);
        });
    }, []);

    return (
        <div style={{ width: '100%' }}>
            <h2 className='signika-negative-word'>Estatísticas</h2>
            <ConfigProvider
                theme={{
                    components: {
                        Statistic: {
                            colorText: colorSelected,
                            colorTextDescription: colorSecondary,
                        },
                    },
                }}
            >
                <Flex wrap style={{ width: '100%' }}>
                    <Tooltip title='Número de palavras mestras que você acertou até agora' placement='top'>
                        <Statistic
                            title='Palavras mestras acertadas'
                            value={statistics.guessedMasterWords}
                            valueStyle={{ color: colorSelected }}
                            style={{ margin: '0 10px' }}
                        />
                    </Tooltip>
                    <Tooltip
                        title='Número de dias seguidos que você têm acertado a palavra mestra. Se você ficar um dia sem revelar perderá seu streak.'
                        placement='top'
                    >
                        <Statistic
                            title='Streak de palavras mestras acertadas'
                            value={statistics.streakMasterWords}
                            valueStyle={{ color: colorSelected }}
                            style={{ margin: '0 10px' }}
                        />
                    </Tooltip>
                    <Tooltip
                        title='Tempo médio gasto pelos jogadores para acertar a palavra mestra de hoje'
                        placement='top'
                    >
                        <Statistic
                            title='Tempo médio para acertar palavra mestra hoje'
                            value={statistics.avgTimeMasterWord ? formatSeconds(statistics.avgTimeMasterWord) : '-'}
                            valueStyle={{ color: colorSelected }}
                            style={{ margin: '0 10px' }}
                        />
                    </Tooltip>
                    <Tooltip title='Número de jogos que você finalizou até agora' placement='top'>
                        <Statistic
                            title='Jogos finalizados'
                            value={statistics.finishedGames}
                            valueStyle={{ color: colorSelected }}
                            style={{ margin: '0 10px' }}
                        />
                    </Tooltip>
                    <Tooltip
                        title='Número de dias seguidos que você acertou todas as palavras do dia. Se você ficar um dia sem acertar tudo perderá seu streak.'
                        placement='top'
                    >
                        <Statistic
                            title='Streak de jogos finalizados'
                            value={statistics.streakFinishedGames}
                            valueStyle={{ color: colorSelected }}
                            style={{ margin: '0 10px' }}
                        />
                    </Tooltip>
                    <Tooltip title='Tempo médio gasto pelos jogadores para finalizar o jogo de hoje' placement='top'>
                        <Statistic
                            title='Tempo médio para finalizar o jogo hoje'
                            value={statistics.avgTimeFinishGame ? formatSeconds(statistics.avgTimeFinishGame) : '-'}
                            valueStyle={{ color: colorSelected }}
                            style={{ margin: '0 10px' }}
                        />
                    </Tooltip>
                </Flex>
            </ConfigProvider>
        </div>
    );
};

export default StatisticsDisplay;
