import axios from 'axios';

const backend = axios.create({
    baseURL: 'https://api.palavramestra.com/prod',
});

export const PlayGame = async (actionType: string, userId: string, action: any) => {
    const response = await backend.post(`/game/play`, {
        actionType,
        userId,
        action,
    });
    return response.data;
};

export const GetWordsClassify = async (limit: number) => {
    const response = await backend.post(`/word/get_classify`, {
        limit,
    });
    return response.data;
};

export const SubmitClassifiedWords = async (key: string, words: any[]) => {
    const response = await backend.post(`/word/classify`, {
        key,
        words,
    });
    return response.data;
};

export const GetStatistics = async (userId: string) => {
    const response = await backend.get(`/game/statistics`, {
        params: {
            userId,
        },
    });
    return response.data;
};

export const GetUnclassifiedGameSet = async () => {
    const response = await backend.get(`/game_set/get_unclassified`);
    return response.data;
};

export const UpdateGameSet = async (key: string, gameSet: any) => {
    const response = await backend.post(`/game_set/push`, {
        key,
        gameSet,
    });
    return response.data;
};

export default PlayGame;
