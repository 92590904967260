import { useEffect, useState } from 'react';

import { Flex, Popover, ConfigProvider, notification, Button } from 'antd';
import { TrophyOutlined } from '@ant-design/icons';

import { colorSecondary, colorSelected } from '../const/colors';

import AchievementsMap from '../const/achievementsMap';

const AchievementsList = (props: { achievements: any; addedAchievements: any }) => {
    const achievements: any[] = props.achievements || [];

    const [api, contextHolder] = notification.useNotification();

    const openNotification = (achievement: any) => {
        const achievementData = AchievementsMap.get(achievement.achievement);
        api.info({
            message: `Conquista desbloqueada: ${achievementData?.title}`,
            description: achievementData?.description,
            icon: <TrophyOutlined style={{ color: colorSelected }} />,
            placement: 'top',
            showProgress: true,
            pauseOnHover: true,
            duration: 10,
        });
    };

    useEffect(() => {
        (props.addedAchievements || []).forEach((achievement: any) => {
            if (AchievementsMap.has(achievement.achievement)) {
                openNotification(achievement);
            }
        });
    }, [props.addedAchievements]);

    return (
        <ConfigProvider
            theme={{
                components: {
                    Popover: {
                        colorTextHeading: 'white',
                        colorText: 'white',
                        colorBgElevated: 'rgba(0,0 , 0, 0.5)',
                    },
                },
            }}
        >
            {contextHolder}
            <Flex wrap>
                {achievements
                    .filter((achievement) => AchievementsMap.has(achievement.achievement))
                    .map((achievement) => AchievementsMap.get(achievement.achievement))
                    .map((achievement) => (
                        <Popover key={achievement?.title} content={achievement?.description} title={achievement?.title}>
                            <div
                                style={{
                                    padding: '6px 7px',
                                    color: colorSelected,
                                    borderWidth: '2px',
                                    borderColor: colorSelected,
                                    borderRadius: '5px',
                                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                    fontSize: '20px',
                                    fontWeight: 'bold',
                                    margin: '4px',
                                }}
                            >
                                <i className={achievement?.icon}></i>
                            </div>
                        </Popover>
                    ))}
                {Array.from(AchievementsMap.keys())
                    .filter(
                        (achievement) =>
                            !achievements.find((userAchievements) => userAchievements.achievement === achievement)
                    )
                    .map((achievement) => AchievementsMap.get(achievement))
                    .map((achievement) => (
                        <Popover
                            key={achievement?.title}
                            content={
                                <div>
                                    <p>{achievement?.description}</p>
                                </div>
                            }
                            title={achievement?.title}
                        >
                            <div
                                style={{
                                    padding: '6px 7px',
                                    color: colorSecondary,
                                    fontSize: '20px',
                                    fontWeight: 'bold',
                                    margin: '4px',
                                    opacity: 0.3,
                                }}
                            >
                                <i className={achievement?.icon}></i>
                            </div>
                        </Popover>
                    ))}
            </Flex>
        </ConfigProvider>
    );
};

export default AchievementsList;
