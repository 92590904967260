import React from 'react';
import { Row, Tooltip, Button, Flex, ConfigProvider } from 'antd';
import { SwapOutlined, DoubleLeftOutlined, EnterOutlined } from '@ant-design/icons';
import { colorSelected, colorSecondary } from '../const/colors';

interface PossibleCharsDisplayProps {
    possibleChars: string[];
    charsLeftToBeUsed: Map<string, number>;
    isTabletOrMobile?: boolean;
    onCharClick?: (char: string) => void; // Used in mobile version
    onShuffleClick?: () => void;
}

const PossibleCharsDisplayDesktop = (props: PossibleCharsDisplayProps) => {
    const displayElements = [];

    const charCounts = new Map();
    for (let i = props.possibleChars.length - 1; i >= 0; i--) {
        const char = props.possibleChars[i];
        if (
            props.charsLeftToBeUsed.has(char) &&
            (charCounts.get(char) || 0) < (props.charsLeftToBeUsed.get(char) || 0)
        ) {
            displayElements.push(
                <div
                    key={i}
                    className='signika-negative-word'
                    style={{
                        margin: 3,
                        fontSize: '25px',
                        color: colorSecondary,
                        borderRadius: 5,
                        width: '40px',
                        textAlign: 'center',
                    }}
                >
                    {char.toUpperCase()}
                </div>
            );
            if (!charCounts.has(char)) {
                charCounts.set(char, 1);
            } else {
                charCounts.set(char, charCounts.get(char) + 1);
            }
        } else {
            // char is unavailable
        }
    }
    return (
        <Tooltip
            title='Essas são as únicas letras permitidas. Multiplas ocorrências indicam que a letra pode ser utilizada mais de uma vez. A palavra mestra utiliza todas elas.'
            placement='left'
        >
            <Row
                style={{
                    margin: '5px 0',
                }}
            >
                {displayElements.reverse()}
            </Row>
        </Tooltip>
    );
};

// TODO: debug buttons using html outputs

const PossibleCharsDisplayMobile = (props: PossibleCharsDisplayProps) => {
    const displayElements = [];

    const charCounts = new Map();
    for (let i = props.possibleChars.length - 1; i >= 0; i--) {
        const char = props.possibleChars[i];
        if (
            props.charsLeftToBeUsed.has(char) &&
            (charCounts.get(char) || 0) < (props.charsLeftToBeUsed.get(char) || 0)
        ) {
            displayElements.push(
                <Button
                    key={i}
                    style={{
                        margin: 3,
                        fontWeight: 'bold',
                        fontSize: '20px',
                        minWidth: '40px',
                        padding: '0',
                    }}
                    type='primary'
                    size='large'
                    onClick={(e) => {
                        // TODO: refactor to handleKeyPress already trim and lower the strings
                        props.onCharClick && props.onCharClick(e.currentTarget.innerText.toLowerCase().trim());
                    }}
                >
                    {char.toUpperCase()}
                </Button>
            );
            if (!charCounts.has(char)) {
                charCounts.set(char, 1);
            } else {
                charCounts.set(char, charCounts.get(char) + 1);
            }
        } else {
            displayElements.push(
                <Button
                    style={{
                        margin: 3,
                        fontWeight: 'bold',
                        fontSize: '20px',
                        minWidth: '40px',
                        padding: '0',
                    }}
                    type='primary'
                    size='large'
                    disabled={true}
                >
                    {char.toUpperCase()}
                </Button>
            );
        }
    }

    return (
        <div>
            <Flex
                style={{
                    margin: '5px 0',
                }}
                align='center'
                justify='center'
                wrap
            >
                <ConfigProvider
                    theme={{
                        components: {
                            Button: {
                                colorPrimary: `black`,
                                colorPrimaryHover: `black`,
                                colorPrimaryActive: `black`,
                                lineWidth: 0,
                            },
                        },
                    }}
                >
                    {displayElements.reverse()}
                </ConfigProvider>
            </Flex>
            <Flex
                style={{
                    margin: '5px 0',
                }}
                align='center'
                justify='center'
                wrap
            >
                <ConfigProvider
                    theme={{
                        components: {
                            Button: {
                                colorPrimary: `black`,
                                colorPrimaryHover: `black`,
                                colorPrimaryActive: `black`,
                                lineWidth: 0,
                            },
                        },
                    }}
                >
                    <Button
                        style={{ fontWeight: 'bold', margin: '0 5px' }}
                        icon={<SwapOutlined />}
                        onClick={props.onShuffleClick}
                    ></Button>
                    <Button
                        style={{ fontWeight: 'bold', margin: '0 5px' }}
                        icon={<DoubleLeftOutlined />}
                        onClick={(e) => {
                            props.onCharClick && props.onCharClick('backspace');
                        }}
                    >
                        Apagar
                    </Button>
                </ConfigProvider>
                <ConfigProvider
                    theme={{
                        components: {
                            Button: {
                                colorText: `black`,
                                colorPrimaryText: `black`,
                                colorPrimary: colorSelected,
                                colorPrimaryHover: colorSelected,
                                colorPrimaryActive: colorSelected,
                                lineWidth: 0,
                                colorBgContainerDisabled: 'rbga(0,0,0,0)',
                            },
                        },
                    }}
                >
                    <Button
                        style={{ fontWeight: 'bold', margin: '0 5px', color: 'black' }}
                        icon={<EnterOutlined />}
                        type='primary'
                        onClick={(e) => {
                            props.onCharClick && props.onCharClick('enter');
                        }}
                    >
                        Enter
                    </Button>
                </ConfigProvider>
            </Flex>
            <br />
        </div>
    );
};

const PossibleCharsDisplay = (props: PossibleCharsDisplayProps) => {
    if (!props.possibleChars) {
        return <></>;
    }

    return props.isTabletOrMobile ? (
        <Row style={{ width: '100%', marginTop: '15px' }}>
            <Flex style={{ width: '100%' }} align='center' justify='center'>
                <PossibleCharsDisplayMobile {...props} />
            </Flex>
        </Row>
    ) : (
        <Row style={{ width: '100%' }}>
            <Flex style={{ width: '100%' }} align='center' justify='center'>
                <PossibleCharsDisplayDesktop {...props} />
                <Tooltip title='Embaralhar letras [Shift]' placement='right'>
                    <Button type='primary' shape='circle' icon={<SwapOutlined />} onClick={props.onShuffleClick} />
                </Tooltip>
            </Flex>
        </Row>
    );
};

export default PossibleCharsDisplay;
