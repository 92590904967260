import { colorSelected, colorSecondary } from './colors';

export default new Map([
    [
        'ACCESS_STREAK_7_DAYS',
        {
            title: 'VISITANTE',
            description: 'Apareça aqui por 7 dias seguidos',
            icon: 'fa-solid fa-camera-retro',
            color: colorSecondary,
        },
    ],
    [
        'ACCESS_STREAK_30_DAYS',
        {
            title: 'FUNCIONÁRIO DO MÊS',
            description: 'Apareça aqui por 30 dias seguidos',
            icon: 'fa-solid fa-clipboard-check',
            color: colorSecondary,
        },
    ],
    [
        'ACCESS_STREAK_100_DAYS',
        {
            title: 'CARA CRACHÁ',
            description: 'Apareça aqui por 100 dias seguidos',
            icon: 'fa-solid fa-id-badge',
            color: colorSecondary,
        },
    ],
    // [
    //     'GUESS_ALL_WORDS_1_TIME',
    //     {
    //         title: 'O PRIBERAM',
    //         description: 'Descubra todas as palavras do dia',
    //         icon: 'fa-solid fa-book',
    //         color: colorSecondary,
    //     },
    // ],
    // [
    //     'GUESS_ALL_WORDS_7_TIMES',
    //     {
    //         title: 'O MICHAELIS',
    //         description: 'Descubra todas as palavras em 7 dias diferentes',
    //         icon: 'fa-solid fa-book-skull',
    //         color: colorSecondary,
    //     },
    // ],
    // [
    //     'GUESS_ALL_WORDS_30_TIMES',
    //     {
    //         title: 'O HOUAISS',
    //         description: 'Descubra todas as palavras em 30 dias diferentes',
    //         icon: 'fa-solid fa-book-medical',
    //         color: colorSecondary,
    //     },
    // ],
    // [
    //     'GUESS_ALL_WORDS_100_TIMES',
    //     {
    //         title: 'O AURÉLIO',
    //         description: 'Descubra todas as palavras em 100 dias diferentes',
    //         icon: 'fa-solid fa-book-atlas',
    //         color: colorSecondary,
    //     },
    // ],
    [
        'GUESS_WORD_LAST_MINUTE',
        {
            title: 'OS ÚLTIMOS SERÃO OS PRIMEIROS',
            description: 'Descubra uma palavra no último minuto do dia',
            icon: 'fa-solid fa-person-running',
            color: colorSecondary,
        },
    ],
    [
        'IN_ORDER',
        {
            title: 'EM ORDEM',
            description: 'Descubra todas as palavras em ordem: de cima pra baixo, da esquerda pra direita',
            icon: 'fa-solid fa-sort',
            color: colorSecondary,
        },
    ],
    [
        'MASTER_WORD_10_SEC',
        {
            title: 'MASTER OF THE WORD',
            description: 'Descubra a palavra mestra em menos de 10 segundos',
            icon: 'fa-solid fa-earth-americas',
            color: colorSelected,
        },
    ],
    [
        'FOCUSED',
        {
            title: 'FOCADO',
            description: 'Descubra a palavra mestra antes de todas as outras',
            icon: 'fa-solid fa-users-rays',
            color: colorSecondary,
        },
    ],
    [
        'FASTEST_FINISH_GAME_IN_THE_DAY',
        {
            title: 'O RECORDISTA',
            description: 'Primeiro jogador a finalizar o jogo no dia',
            icon: 'fa-solid fa-person-running',
            color: colorSecondary,
        },
    ],
    // [
    //     'FASTEST_GUESS_MASTER_WORD_IN_THE_DAY',
    //     {
    //         title: 'O RECORDISTA',
    //         description: 'Primeiro jogador a acertar a palavra mestra no dia',
    //         icon: 'fa-solid fa-person-running',
    //         color: colorSecondary,
    //     },
    // ],
    [
        'GUESS_MOON_WORD',
        {
            title: 'O ASTRONAUTA',
            description: 'Acerte a "lua"',
            icon: 'fa-solid fa-moon',
            color: colorSecondary,
        },
    ],
]);
