import { useState, useEffect } from 'react';
import { Flex, Row, Col, Divider, Spin, Card, Statistic, Tooltip } from 'antd';
import { TrophyOutlined, LoadingOutlined } from '@ant-design/icons';
import { colorBackground, colorSecondary, colorSelected } from '../const/colors';
import useEventListener from '@use-it/event-listener';
import { Animate, AnimateGroup } from 'react-simple-animate';
import { useMediaQuery } from 'react-responsive';

import PossibleCharsDisplay from './PossibleCharsDisplay';
import WordsDisplay from './WordsDisplay';
import TriesCountDisplay from './TriesCountDisplay';
import TimerDisplay from './TimerDisplay';
import RevealedWordsList from './RevealedWordsList';
import AchievementsList from './AchievementsList';
import StatisticsDisplay from './StatisticsDisplay';

import PlayGame from '../apis/backend';

// TODO: add small animations to interactions
// TODO: create const file that match consts in the backend

const TextInputDisplayChar = (props: {
    char: string;
    drawBackground: boolean;
    color?: string;
    playAnimation: boolean;
    isTabletOrMobile?: boolean;
}) => {
    let color = colorSelected;
    if (props.char == '_') {
        color = 'rgba(255, 255, 255, 0.0)';
    } else if (props.color != null) {
        color = props.color;
    }
    return (
        <div
            key={props.char}
            className='signika-negative-word'
            style={{
                margin: 3,
                background: props.drawBackground ? 'rgba(255, 255, 255, 0.1)' : 'none',
                fontSize: props.isTabletOrMobile ? '30px' : '50px',
                color,
                borderRadius: 5,
                width: props.isTabletOrMobile ? '40px' : '70px',
                textAlign: 'center',
            }}
        >
            {props.playAnimation ? (
                <Animate
                    play={props.playAnimation}
                    start={{ opacity: 0, transform: 'translateY(-10px)' }}
                    end={{ opacity: 1, transform: 'translateY(0)' }}
                    duration={0.2}
                >
                    {props.char}
                </Animate>
            ) : (
                props.char
            )}
        </div>
    );
};

interface TextInputDisplayProps {
    input: { str: string; lastKey: string };
    possibleChars?: string[];
    tryCount: number;
    isTabletOrMobile?: boolean;
}

const TextInputDisplay = (props: TextInputDisplayProps) => {
    let input = props.input.str;
    if (props.possibleChars) {
        const nMissingSpots = props.possibleChars.length - props.input.str.length;
        for (let i = 0; i < nMissingSpots; i++) {
            input += '_';
        }
    }

    return (
        <Row
            style={{
                margin: '5px 0',
            }}
        >
            {input
                .toUpperCase()
                .split('')
                .map((char, index) => (
                    <TextInputDisplayChar
                        key={index}
                        char={char}
                        drawBackground={true}
                        playAnimation={props.input.lastKey != 'Backspace' && index === props.input.str.length - 1}
                        isTabletOrMobile={props.isTabletOrMobile}
                    />
                ))}
        </Row>
    );
};

// TODO: display unavailable in cell phone? Or make it available on day one?
// TODO: get new datasets with no verbo conjugados
// TODO: mudar a logo pra horizontal e centralizar?
// TODO: adicionar skeleton loading
// TODO: use colors to avoid using texto as action result

const countChars = (word: string | any[]) => {
    const charCounts = new Map();
    for (const letter of word) {
        if (charCounts.has(letter)) {
            charCounts.set(letter, charCounts.get(letter) + 1);
        } else {
            charCounts.set(letter, 1);
        }
    }
    return charCounts;
};

const getGameMessage = (gameState: any) => {
    if (gameState?.isError) {
        // Todo: how to handle error where the user is playing when the fday flips?
        return 'Error: ' + gameState?.error;
    } else if (gameState?.game?.status === 'FINISHED') {
        return 'O jogo acabou! Parabéns! Até amanhã!';
    } else if (gameState?.actionResult === 'WORD_DOESNT_EXIST') {
        return 'Essa palavra não está no nosso dicionário. Tente novamente.';
    } else if (gameState?.actionResult === 'WORD_NOT_FOUND') {
        return 'Palavra não está no tabuleiro. Tente novamente.';
    } else if (gameState?.actionResult === 'WORD_ALREADY_TRIED') {
        return 'Palavra já foi tentada.';
    } else if (gameState?.actionResult === 'WORD_FOUND') {
        return 'Palavra encontrada! Parabéns!';
    } else {
        return 'Pressione Enter para enviar a palavra.';
    }
};

const shuffleArray = (array: any[]) => {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
};

let charsLeftToBeUsed = new Map();
const Game = (props: { userId: string }) => {
    const [initialLoading, setInitialLoading] = useState(true);
    const [input, setInput] = useState({ str: '', lastKey: '' });
    const [gameState, setGameState] = useState<any>({ isLoading: true });
    const [possibleChars, setPossibleChars] = useState<any[]>([]); // Just to change order

    const handleKeyPress = (key: string) => {
        if (key === 'shift') {
            setPossibleChars(shuffleArray(possibleChars));
        } else if (gameState.isLoading || gameState?.game?.status === 'FINISHED') {
            // TODO: display loading ?
            return;
        } else if ((key === '\n' || key === 'enter') && input.str.length > 2) {
            setGameState({ isLoading: true, ...gameState });
            PlayGame('GUESS_WORD', props.userId, { word: input.str }).then((gameState) => {
                setGameState(gameState);
                setInput({ str: '', lastKey: key });
                charsLeftToBeUsed = countChars(gameState?.game?.gameSet?.possibleChars || []);
            });
        } else if (key === 'backspace' && input.str.length > 0) {
            charsLeftToBeUsed.set(
                input.str[input.str.length - 1],
                charsLeftToBeUsed.get(input.str[input.str.length - 1]) + 1
            );
            setInput({ str: input.str.slice(0, -1), lastKey: key });
        } else if (
            input.str.length < gameState?.game?.gameSet?.possibleChars?.length &&
            key.length == 1 &&
            key.toLowerCase().match(/[a-z]/i) &&
            charsLeftToBeUsed.has(key.toLowerCase()) &&
            charsLeftToBeUsed.get(key.toLowerCase()) > 0
        ) {
            charsLeftToBeUsed.set(key, charsLeftToBeUsed.get(key) - 1);
            setInput({ str: input.str + key, lastKey: key });
        }
    };

    useEventListener('keydown', ({ key }: any) => handleKeyPress(key.toLowerCase()));

    useEffect(() => {
        setInitialLoading(true);
        setGameState({ isLoading: true, ...gameState });
        PlayGame('INIT', props.userId, null).then((gameState) => {
            setGameState(gameState);
            setPossibleChars(gameState?.game?.gameSet?.possibleChars || []);
            charsLeftToBeUsed = countChars(gameState?.game?.gameSet?.possibleChars || []);
            setInitialLoading(false);
        });
    }, []);

    const onShuffleClick = () => {
        setPossibleChars(shuffleArray(possibleChars));
    };

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

    const interactions = [
        <Row style={{ width: '100%' }}>
            <Flex style={{ width: '100%' }} align='center' justify='center'>
                <TextInputDisplay
                    input={input}
                    possibleChars={gameState?.game?.gameSet?.possibleChars || '_________'.split('')}
                    tryCount={gameState?.game?.tryCount || 0}
                    isTabletOrMobile={isTabletOrMobile}
                />
            </Flex>
        </Row>,
        initialLoading ? (
            <div>
                <br />
                <Row style={{ width: '100%' }}>
                    <Flex style={{ width: '100%' }} align='center' justify='center'>
                        <Spin indicator={<LoadingOutlined spin />} size='large' />
                    </Flex>
                </Row>
            </div>
        ) : (
            <div>
                <PossibleCharsDisplay
                    possibleChars={possibleChars}
                    charsLeftToBeUsed={charsLeftToBeUsed}
                    isTabletOrMobile={isTabletOrMobile}
                    onShuffleClick={onShuffleClick}
                    onCharClick={handleKeyPress}
                />
                <Row style={{ width: '100%' }}>
                    <Flex style={{ width: '100%' }} align='center' justify='center'>
                        <Animate
                            play={!gameState.isLoading}
                            start={{ opacity: 0, transform: 'translateX(-30px)' }}
                            end={{ opacity: 1, transform: 'translateX(0)' }}
                            duration={0.1}
                        >
                            {getGameMessage(gameState)}
                        </Animate>
                    </Flex>
                </Row>
            </div>
        ),
        isTabletOrMobile ? (
            <></>
        ) : (
            <div>
                <br />
                <br />
                <br />
            </div>
        ),
        <Row style={{ width: '100%' }}>
            <Flex style={{ width: '100%' }} align='center' justify='center'>
                <TimerDisplay gameState={gameState} />
            </Flex>
        </Row>,
    ];

    return (
        <div style={{ minHeight: '60vh', width: '100%' }}>
            {isTabletOrMobile ? (
                <></>
            ) : (
                <div>
                    <br />
                    <br />
                    <br />
                </div>
            )}
            <WordsDisplay gameState={gameState} isTabletOrMobile={isTabletOrMobile} />
            <br />
            <br />
            <br />
            {isTabletOrMobile ? (
                <div
                    style={{
                        position: 'fixed',
                        bottom: '0',
                        zIndex: '100',
                        width: '100%',
                        background: colorBackground,
                        paddingTop: '10px',
                        boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.75)',
                    }}
                >
                    {interactions}
                </div>
            ) : (
                interactions
            )}
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <Row style={{ width: '100%' }}>
                <Flex style={{ width: '100%' }} align='center' justify='center'>
                    <div style={{ width: '70%' }}>
                        <Divider style={{ opacity: 0.2 }} />
                    </div>
                </Flex>
            </Row>
            <Row style={{ width: '100%' }}>
                <Flex style={{ width: '100%' }} align='center' justify='center'>
                    <div style={{ width: '70%' }}>
                        <Row style={{ width: '100%' }}>
                            <Col span={isTabletOrMobile ? 24 : 4}>
                                <Flex style={{ width: '100%' }} align='center' justify='center'>
                                    <div>
                                        <h2 className='signika-negative-word'>Acertos</h2>
                                        <RevealedWordsList gameState={gameState} />
                                    </div>
                                </Flex>
                            </Col>
                            <Col span={isTabletOrMobile ? 24 : 10}>
                                <Flex style={{ width: '100%' }} align='center' justify='flex-start'>
                                    <StatisticsDisplay gameState={gameState} userId={props.userId} />
                                </Flex>
                            </Col>
                            <Col span={isTabletOrMobile ? 24 : 10}>
                                <Flex style={{ width: '100%' }} align='center' justify='flex-start'>
                                    <div style={{ textAlign: 'center', width: '100%' }}>
                                        <h2 className='signika-negative-word'>Conquistas</h2>
                                        <AchievementsList
                                            achievements={gameState?.user?.achievements}
                                            addedAchievements={gameState?.addedAchievements}
                                        />
                                    </div>
                                </Flex>
                            </Col>
                        </Row>
                    </div>
                </Flex>
            </Row>
            <br />
            <br />
            <br />
            <br />
            {isTabletOrMobile ? (
                <>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </>
            ) : (
                <div></div>
            )}
        </div>
    );
};

export default Game;
