import { useEffect } from 'react';
import { Row, List, ConfigProvider, Flex, Timeline } from 'antd';
import { Animate } from 'react-simple-animate';
import moment from 'moment';

import { colorSecondary, colorSelected } from '../const/colors';

import formatSecondsDuration from '../utils/formatSecondsDuration';

const RevealedWordsList = (props: { gameState: any }) => {
    if (
        !props.gameState?.game?.revealedWords ||
        props.gameState?.game?.revealedWords.filter((revealedWord: any) => revealedWord.type === 'REVEALED').length ===
            0
    ) {
        return <></>;
    }

    return (
        <ConfigProvider
            theme={{
                components: {
                    List: {
                        colorText: colorSecondary,
                        lineWidth: 0,
                    },
                },
            }}
        >
            <List
                size='small'
                bordered
                dataSource={props?.gameState?.game?.revealedWords
                    .filter((revealedWord: any) => revealedWord.type === 'REVEALED')
                    .sort((a: any, b: any) => moment.utc(a.revealedDate).diff(moment.utc(b.revealedDate), 'seconds'))}
                renderItem={(item: any) => (
                    <List.Item style={{ padding: 0 }}>
                        <Flex wrap>
                            <div style={{ fontWeight: 'bold', color: colorSelected }}>
                                +
                                {formatSecondsDuration(
                                    moment
                                        .utc(item.revealedDate)
                                        .diff(moment.utc(props.gameState?.game?.creationDate), 'seconds')
                                )}
                            </div>
                            &nbsp;
                            <div style={{ fontWeight: 'bold' }}>{item.word.toUpperCase()}</div>
                        </Flex>
                    </List.Item>
                )}
            />
        </ConfigProvider>
    );
};

export default RevealedWordsList;
