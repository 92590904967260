import { Flex, Button, Row, Col, Spin, Space, Input } from 'antd';
import { UploadOutlined, LoadingOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';

import { GetUnclassifiedGameSet, UpdateGameSet, SubmitClassifiedWords } from '../apis/backend';

const WordClassification = {
    APPROVED: 'APPROVED',
    REMOVED: 'REMOVED',
    UNCLASSIFIED: 'UNCLASSIFIED',
};

// TODO: add simple front end validation

const GameSetBuilder = () => {
    const [gameSet, setGameSet] = useState<any>({});
    const [counts, setCounts] = useState<any>({});
    const [words, setWords] = useState(new Map<string, string>([]));
    const [loading, setLoading] = useState(true);
    const [key, setKey] = useState('');

    useEffect(() => {
        setLoading(true);
        GetUnclassifiedGameSet().then((data: any) => {
            console.log(data);
            setWords(
                new Map<string, string>(
                    data.gameSet.possibleGuessWords.map((word: string) => [word, WordClassification.REMOVED])
                )
            );
            setGameSet(data.gameSet);
            setCounts(data.counts);
            setLoading(false);
        });
    }, []);

    const wordsByLength = Array.from(words.keys()).reduce((acc: Map<number, string[]>, word: string) => {
        const length = word.length;
        if (!acc.has(length)) {
            acc.set(length, []);
        }
        acc.get(length)?.push(word);
        return acc;
    }, new Map<number, string[]>());

    return loading ? (
        <Flex justify='center' align='center' gap='small' style={{ minHeight: '60vh', width: '100%' }}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
        </Flex>
    ) : (
        <div style={{ padding: '0 10px', minHeight: '80vh', width: '100%' }}>
            <Flex justify='center' gap='small' style={{ textAlign: 'center' }}>
                <h1>Ferramenta de criação de casos de jogo</h1>
            </Flex>
            <Flex justify='center' gap='small'>
                <div>
                    {counts?.approvedCount || 0} casos de teste aprovados de {counts?.totalCount || 0},{' '}
                    {counts?.removedCount} removidos e o dia atual {counts?.order || 0}
                </div>
            </Flex>
            <br />
            <br />
            <h1 style={{ width: '100%', textAlign: 'center' }}>{gameSet.masterWord}</h1>
            {Array.from(wordsByLength.keys()).map((length: number) => (
                <Row style={{ width: '100%', margin: '10px 0' }}>
                    <Flex justify='center' align='center' gap='small' style={{ width: '100%' }}>
                        <h2>{length} letras</h2>
                    </Flex>
                    <Flex justify='center' align='center' wrap gap='small' style={{ width: '100%' }}>
                        {wordsByLength.get(length)?.map((word: string, index: number) => (
                            <Button
                                key={index}
                                type='primary'
                                style={{
                                    margin: '5px',
                                    backgroundColor:
                                        words.get(word) === WordClassification.APPROVED
                                            ? 'green'
                                            : words.get(word) === WordClassification.REMOVED
                                              ? 'red'
                                              : 'black',
                                }}
                                onClick={(p: any) => {
                                    const word = p.target.innerText;
                                    switch (words.get(word)) {
                                        case WordClassification.APPROVED:
                                            words.set(word, WordClassification.REMOVED);
                                            break;
                                        case WordClassification.REMOVED:
                                            words.set(word, WordClassification.APPROVED);
                                            break;
                                    }
                                    setWords(new Map(words));
                                }}
                            >
                                {word}
                            </Button>
                        ))}
                    </Flex>
                </Row>
            ))}
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <Flex justify='center' gap='small'>
                <Space.Compact>
                    <Input
                        value={key}
                        placeholder='Cole a chave de api'
                        type='password'
                        onChange={(e) => {
                            setKey(e.target.value);
                        }}
                    />
                    <Button
                        type='primary'
                        icon={<UploadOutlined />}
                        size='large'
                        style={{ backgroundColor: 'green' }}
                        onClick={() => {
                            setLoading(true);
                            UpdateGameSet(key, {
                                ...gameSet,
                                possibleGuessWords: [
                                    ...Array.from(words)
                                        .filter(
                                            ([word, classification]) => classification === WordClassification.APPROVED
                                        )
                                        .map(([word, _]) => word),
                                    gameSet.masterWord,
                                ],
                                classification: WordClassification.APPROVED,
                            })
                                .then((data: any) => {
                                    GetUnclassifiedGameSet().then((data: any) => {
                                        setWords(
                                            new Map<string, string>(
                                                data.gameSet.possibleGuessWords.map((word: string) => [
                                                    word,
                                                    WordClassification.REMOVED,
                                                ])
                                            )
                                        );
                                        setGameSet(data.gameSet);
                                        setCounts(data.counts);
                                        setLoading(false);
                                    });
                                })
                                .catch((e) => {
                                    console.error('Error submitting', e);
                                });
                        }}
                    >
                        Aprovar
                    </Button>
                    <Button
                        type='primary'
                        icon={<UploadOutlined />}
                        size='large'
                        style={{ backgroundColor: 'red' }}
                        onClick={() => {
                            setLoading(true);
                            UpdateGameSet(key, {
                                ...gameSet,
                                classification: WordClassification.REMOVED,
                            })
                                .then((data: any) => {
                                    GetUnclassifiedGameSet().then((data: any) => {
                                        setWords(
                                            new Map<string, string>(
                                                data.gameSet.possibleGuessWords.map((word: string) => [
                                                    word,
                                                    WordClassification.REMOVED,
                                                ])
                                            )
                                        );
                                        setGameSet(data.gameSet);
                                        setCounts(data.counts);
                                        setLoading(false);
                                    });
                                })
                                .catch((e) => {
                                    console.error('Error submitting', e);
                                });
                        }}
                    >
                        Remover
                    </Button>
                </Space.Compact>
            </Flex>
        </div>
    );
};

export default GameSetBuilder;
