import { useState, useRef, useEffect } from 'react';
import { useTimer } from 'react-use-precision-timer';
import { Tooltip } from 'antd';
import { colorSecondary, colorSelected } from '../const/colors';
import { useAnimate } from 'react-simple-animate';
import moment from 'moment';

const formatSeconds = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
};

// TODO: adicionar o tempo em cada palavra eh acertada -> adicionr no backend

const TimerDisplay = (props: { gameState: any }) => {
    const [timeInSeconds, setTime] = useState<number>(
        moment.utc().diff(moment(props.gameState?.game?.creationDate), 'seconds')
    );

    useEffect(() => {
        if (props.gameState?.game?.status === 'FINISHED') {
            setTime(
                moment
                    .utc(props.gameState?.game?.finishedDate)
                    .diff(moment.utc(props.gameState?.game?.creationDate), 'seconds')
            );
        } else {
            setTime(moment.utc().diff(moment(props.gameState?.game?.creationDate), 'seconds'));
        }
    }, [props.gameState]);

    const mainTimer = useTimer(
        {
            delay: 1000,
            startImmediately: true,
        },
        () => {
            if (props.gameState?.game?.status !== 'FINISHED') {
                setTime(timeInSeconds + 1);
                play(true);
            }
        }
    );

    const { play, style, isPlaying } = useAnimate({
        end: { opacity: 0.9, transform: 'translateY(-1px) rotate(3deg)' },
        start: { opacity: 1, transform: 'translateY(0) rotate(0)' },
        duration: 0.07,
        onComplete: () => {
            play(false);
        },
    });

    return (
        <Tooltip title='Tempo utilizado' placement='right'>
            <div
                key={timeInSeconds}
                style={{
                    fontSize: 50,
                    fontFamily: 'Signika Negative',
                    margin: '0 10px',
                    color: props.gameState?.game?.status === 'FINISHED' ? colorSelected : colorSecondary,
                    ...style,
                }}
            >
                {formatSeconds(timeInSeconds)}
            </div>
        </Tooltip>
    );
};

export default TimerDisplay;
